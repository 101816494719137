import Vue from 'vue';
import VueRouter from 'vue-router';
import Guides from 'vue-components/Guides';
import HoneybadgerVue from '@honeybadger-io/vue'
import { preloadData } from 'helpers/preloaded';

export default function startup() {
	if (process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'production') {
		const hb_config = {
			apiKey: process.env.HONEYBADGER_JS_API_KEY,
			environment: process.env.NODE_ENV,
			revision: process.env.HEROKU_SLUG_COMMIT
		}

		Vue.use(HoneybadgerVue, hb_config)
	}

	const demo = preloadData().growth_suite_demo;
	const demoRootPath = preloadData().demo_root_path;
	Vue.prototype.$demo = demo;
	Vue.prototype.$demoRootPath = demoRootPath;

	const startupLink = document.head.querySelector("link[rel=startup]");
	const vueStartupLink = document.head.querySelector("link[rel=vue_startup]");

	if (startupLink == null) {
		console.warn('Missing startup Link Element');
		return;
	} else {
		if (vueStartupLink) {
			const [filename, action] = vueStartupLink.attributes.href.textContent.split(":", 2);

			Vue.use(VueRouter);
			Vue.component('App', () => import(`./controllers/${filename}/${action}.vue`));

			const router = new VueRouter({
				mode: 'history'
			});

			new Vue({ el: '#main', router });
		} else {
			const [filename, action] = startupLink.attributes.href.textContent.split(":", 2)

			import(`./controllers/${filename}.js`).then(module => {
				if (action in module.default) {
					module.default[action]()
				}
			});
		}

		if (document.getElementById('guides')) {
			new Vue({
				el: '#guides',
				...Guides
			});
		}
	}
}
