<template>
	<aside v-if="activeGuide" class="guide__footer">
		<div class="guide__titles">
			<div class="guide__footer-title" v-html="activeGuide.title"/>
			<div class="guide__footer-subtitle">
				Step {{ activeStepNumber }} of {{ totalSteps }}
			</div>
		</div>
		<button class="guide__footer-button --secondary" @click="exitGuide">
			Leave Tour
		</button>
	</aside>
</template>

<script>
import { createPopper } from '@popperjs/core';
import Store from 'store';
import { mapGetters } from 'vuex';
import { GETTER_TYPES, ACTION_TYPES } from 'store/modules/guides/types';

export default {
	store: Store,

	data: () => ({
		mountRetriesRemaining: 10
	}),

	computed: {
		...mapGetters([
			GETTER_TYPES.activeGuide,
			GETTER_TYPES.activeStep,
			GETTER_TYPES.activeStepNumber,
			GETTER_TYPES.totalSteps
		])
	},

	watch: {
		activeGuide (guide) {
			this.clearGuideDomElements();
			if (guide) {
				document.body.classList.add('active-guide');
			} else {
				document.body.classList.remove('active-guide');
			}
		},

		async activeStep (step, oldStep) {
			await this.handleActiveStepChange(step, oldStep);
		}
	},

	mounted () {
		this.$store.dispatch(`${ACTION_TYPES.setActiveGuideFromLocalStorage}`);

		this.$store.state.guidesModule.guides.forEach((guide) => {
			document.addEventListener('click', (event) => {
				const target = event.target.closest(guide.activationTargetSelector);
				if (target && !target.classList.contains('--disabled')) {
					this.$store.dispatch(`${ACTION_TYPES.setActiveGuide}`, guide.id);
				}
			});
		});
	},

	methods: {
		async handleActiveStepChange (step, oldStep) {
			if (!step) {
				return;
			}

			const target = document.querySelector(step.targetSelector);
			const completeStepTargets = document.querySelectorAll(step.completeTargetSelector);
			const targetsAreMissing = !target || !completeStepTargets.length;

			if (targetsAreMissing) {
				if (this.mountRetriesRemaining > 0) {
					this.mountRetriesRemaining--;

					setTimeout(() => {
						this.handleActiveStepChange(step, oldStep);
					}, 300);

					return;
				}

				// If there is no old step AND target elements are missing, a new page was loaded outside the flow of the guide, exit
				if (!oldStep) {
					this.exitGuide();
				}

				return;
			}

			this.clearGuideDomElements();

			if (step.beforeStepCallback) {
				await step.beforeStepCallback();
			}

			this.registerCompleteStepClickEvents(completeStepTargets);

			const hotspot = this.createHotspot();
			const tooltip = this.createTooltip(step.tooltipPlacement, step.text);

			this.insertHotspotAndTooltipAsPoppers(target, hotspot, step.hotspotPlacement, tooltip, step.tooltipPlacement);
			this.registerShowHideEventsForTooltip(hotspot, tooltip);
		},

		exitGuide () {
			this.$store.dispatch(`${ACTION_TYPES.exitGuide}`);
		},

		clearGuideDomElements () {
			document.querySelectorAll('.guide__hotspot, .guide__hotspot-tooltip').forEach((hotspot) => {
				hotspot.remove();
			});
		},

		registerCompleteStepClickEvents (completeStepTargets) {
			completeStepTargets.forEach((target) => {
				target.removeEventListener('click', this.handleCompleteStepClick);
				target.addEventListener('click', this.handleCompleteStepClick);
			});
		},

		handleCompleteStepClick () {
			this.$store.dispatch(`${ACTION_TYPES.advanceGuide}`);
		},

		createHotspot () {
			const hotspot = document.createElement('button');
			hotspot.classList.add('guide__hotspot');
			return hotspot;
		},

		createTooltip (placement, text) {
			const tooltip = document.createElement('div');
			tooltip.classList.add('guide__hotspot-tooltip');
			tooltip.classList.add(`--${placement}`);
			tooltip.innerText = text;
			return tooltip;
		},

		insertHotspotAndTooltipAsPoppers (target, hotspot, hotspotPlacement, tooltip, tooltipPlacement) {
			document.body.insertBefore(hotspot, null);
			document.body.insertBefore(tooltip, null);

			createPopper(target, hotspot, {
				placement: hotspotPlacement || 'top'
			});
			createPopper(hotspot, tooltip, {
				placement: tooltipPlacement || 'top',
				modifiers: [{
					name: 'offset',
					options: {
						offset: [0, 20]
					}
				}]
			});
		},

		registerShowHideEventsForTooltip (hotspot, tooltip) {
			const showEvents = ['mouseenter', 'focus'];
			const hideEvents = ['mouseleave', 'blur'];

			showEvents.forEach((event) => {
				hotspot.removeEventListener(event, () => tooltip.setAttribute('data-show', ''));
				hotspot.addEventListener(event, () => tooltip.setAttribute('data-show', ''));
			});

			hideEvents.forEach((event) => {
				hotspot.removeEventListener(event, () => tooltip.removeAttribute('data-show', ''));
				hotspot.addEventListener(event, () => tooltip.removeAttribute('data-show', ''));
			});
		}
	}
};
</script>
