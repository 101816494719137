var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.activeGuide
    ? _c("aside", { staticClass: "guide__footer" }, [
        _c("div", { staticClass: "guide__titles" }, [
          _c("div", {
            staticClass: "guide__footer-title",
            domProps: { innerHTML: _vm._s(_vm.activeGuide.title) },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "guide__footer-subtitle" }, [
            _vm._v(
              "\n\t\t\tStep " +
                _vm._s(_vm.activeStepNumber) +
                " of " +
                _vm._s(_vm.totalSteps) +
                "\n\t\t"
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "guide__footer-button --secondary",
            on: { click: _vm.exitGuide },
          },
          [_vm._v("\n\t\tLeave Tour\n\t")]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }